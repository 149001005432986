import apiClient from 'services/axios'

export async function getDailySend(parameter = {}) {
  return apiClient
    .get('/user/daily-send/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function getDailySendDashboard(parameter = {}) {
  return apiClient
    .get('/user/daily-send/search-dashboard', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filterDailySend(parameter = {}) {
  return apiClient
    .get('/user/daily-send/filter', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filterDailySendDashboard(parameter = {}) {
  return apiClient
    .get('/user/daily-send/filter-dashboard', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function optionsUploadDailySend(parameter = {}) {
  return apiClient
    .get('/user/daily-send/options-upload-file', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadDailySend(values) {
  return apiClient
    .post('/user/daily-send/upload-file', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function getDetailDailySend(id, parameter = {}) {
  return apiClient
    .get(`/user/detail-daily-send/${id}/search`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function getDetailDailySendDashboard(id, parameter = {}) {
  return apiClient
    .get(`/user/detail-daily-send/${id}/search-dashboard`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createData(parameter = {}) {
  return apiClient
    .get('/user/daily-send/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeData(values) {
  return apiClient
    .post('/user/daily-send/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editData(id, parameter = {}) {
  return apiClient
    .get(`/user/daily-send/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateData(id, values) {
  return apiClient
    .post(`/user/daily-send/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteData(id) {
  return apiClient
    .delete(`/user/daily-send/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
