export default async function getMenuData() {
  return [
    {
      title: 'Dashboard',
      key: 'dashboard',
      icon: 'fe fe-globe',
      auths: ['user'],
      children: [
        {
          title: 'Progress Approval',
          key: 'dashboard-progress-approval',
          attribute: 'Read Dashboard Progress Approval',
          url: '/admin/dashboard/progress-approval',
        },
        {
          title: 'Distributor',
          key: 'dashboard-distributor',
          attribute: 'Read Dashboard Distributor',
          url: '/admin/dashboard/distributor',
        },
        // {
        //   title: 'Distributor Enseval',
        //   key: 'dashboard-distributor-enseval',
        //   attribute: 'Read Dashboard Enseval',
        //   url: '/admin/dashboard/distributor-enseval',
        // },
        {
          title: 'Sales',
          key: 'dashboard-sales',
          attribute: 'Read Dashboard Sales',
          url: '/admin/dashboard/sales',
        },
        {
          title: 'Daily Send',
          key: 'dashboard-daily-send',
          attribute: 'Read Dashboard Daily Send',
          url: '/admin/dashboard/daily-send',
        },
        {
          title: 'SFA',
          key: 'dashboard-sfa',
          attribute: 'Read Dashboard SFA',
          url: '/admin/dashboard/sfa',
        },
        {
          title: 'Kindis',
          key: 'dashboard-kindis',
          attribute: 'Read Dashboard Kindis',
          url: '/admin/dashboard/kindis',
        },
        {
          title: 'User',
          key: 'dashboard-user',
          attribute: 'Read Dashboard User',
          url: '/admin/dashboard/user',
        },
      ],
    },
    {
      title: 'Master Data',
      key: 'master-data',
      icon: 'fe fe-layers',
      auths: ['user'],
      children: [
        {
          title: 'Business',
          key: 'business',
          attribute: 'Read Business',
          url: '/admin/master-data/business',
        },
        {
          title: 'Product',
          key: 'product',
          children: [
            {
              title: 'Brand Group',
              key: 'brandGroup',
              attribute: 'Read Brand Group',
              url: '/admin/master-data/product/brand-group',
            },
            {
              title: 'Brand',
              key: 'brand',
              attribute: 'Read Brand',
              url: '/admin/master-data/product/brand',
            },
            {
              title: 'Brand Business',
              key: 'brandBusiness',
              attribute: 'Read Brand Business',
              url: '/admin/master-data/product/brand-business',
            },
            {
              title: 'Brand Category',
              key: 'brandCategory',
              attribute: 'Read Brand Category',
              url: '/admin/master-data/product/brand-category',
            },
            {
              title: 'Category',
              key: 'category',
              attribute: 'Read Category',
              url: '/admin/master-data/product/category',
            },
            {
              title: 'Category Sub',
              key: 'categorySub',
              attribute: 'Read Category Sub',
              url: '/admin/master-data/product/category-sub',
            },
            {
              title: 'Category CEO',
              key: 'categoryCeo',
              attribute: 'Read Category CEO',
              url: '/admin/master-data/product/category-ceo',
            },
            {
              title: 'Category Marketing',
              key: 'categoryAcc',
              attribute: 'Read Category Marketing',
              url: '/admin/master-data/product/category-marketing',
            },
            {
              title: 'Category Vitalis',
              key: 'categoryVitalis',
              attribute: 'Read Category Vitalis',
              url: '/admin/master-data/product/category-vitalis',
            },
            // {
            //   title: 'Category Accenture',
            //   key: 'categoryAccenture',
            //   attribute: 'Read Category Accenture',
            //   url: '/admin/master-data/product/category-accenture',
            // },
            {
              title: 'Variant',
              key: 'variant',
              attribute: 'Read Variant',
              url: '/admin/master-data/product/variant',
            },
            {
              title: 'Range',
              key: 'range',
              attribute: 'Read Range',
              url: '/admin/master-data/product/product-range',
            },
            {
              title: 'Range Variant',
              key: 'rangeVariant',
              attribute: 'Read Range Variant',
              url: '/admin/master-data/product/range-variant',
            },
            {
              title: 'Range Marketing',
              key: 'rangeMarketing',
              attribute: 'Read Range Marketing',
              url: '/admin/master-data/product/range-marketing',
            },
            {
              title: 'Product Price',
              key: 'productPrice',
              attribute: 'Read Product Price',
              url: '/admin/master-data/product/product-price',
            },
            {
              title: 'Product MSS Monthly',
              key: 'productMssMonthly',
              attribute: 'Read Product MSS Monthly',
              url: '/admin/master-data/product/product-mss-monthly',
            },
            {
              title: 'Product TOP',
              key: 'productTop',
              attribute: 'Read Product Top',
              url: '/admin/master-data/product/product-top',
            },
            {
              title: 'Product TOP Monthly',
              key: 'productTopMonthly',
              attribute: 'Read Product Top Monthly',
              url: '/admin/master-data/product/product-top-monthly',
            },
            {
              title: 'Product Listing',
              key: 'productListing',
              attribute: 'Read Product Listing',
              url: '/admin/master-data/product/product-listing',
            },
            {
              title: 'Product Primary',
              key: 'productPrimary',
              attribute: 'Read Product Primary',
              url: '/admin/master-data/product/product-primary',
            },
            {
              title: 'Product',
              key: 'product',
              attribute: 'Read Product',
              url: '/admin/master-data/product/product',
            },
            {
              title: 'Product Dist',
              key: 'productDist',
              attribute: 'Read Product Dist',
              url: '/admin/master-data/product/product-dist',
            },
            {
              title: 'Product Bundle',
              key: 'productBundle',
              attribute: 'Read Product Bundle',
              url: '/admin/master-data/product/product-bundle',
            },
          ],
        },
        {
          title: 'Distributor',
          key: 'distributor',
          children: [
            {
              title: 'Region',
              key: 'region',
              attribute: 'Read Region',
              url: '/admin/master-data/mst-distributor/region',
            },
            {
              title: 'Area',
              key: 'area',
              attribute: 'Read Area',
              url: '/admin/master-data/mst-distributor/area',
            },
            {
              title: 'Area Sub',
              key: 'areaSub',
              attribute: 'Read Area Sub',
              url: '/admin/master-data/mst-distributor/area-sub',
            },
            {
              title: 'EPM Area Sub',
              key: 'epmAreaSub',
              attribute: 'Read EPM Area Sub',
              url: '/admin/master-data/mst-distributor/epm-area-sub',
            },
            {
              title: 'Mensa Area Sub',
              key: 'mensaAreaSub',
              attribute: 'Read Mensa Area Sub',
              url: '/admin/master-data/mst-distributor/mensa-area-sub',
            },
            {
              title: 'Distributor Primary',
              key: 'distributorPrimary',
              attribute: 'Read Distributor Primary',
              url: '/admin/master-data/mst-distributor/distributor-primary',
            },
            // {
            //   title: 'Distributor EPM',
            //   key: 'distributorEpm',
            //   attribute: 'Read Distributor EPM',
            //   url: '/admin/master-data/mst-distributor/distributor-epm',
            // },
            {
              title: 'Shipping Point',
              key: 'shippingPoint',
              attribute: 'Read Shipping Point',
              url: '/admin/master-data/mst-distributor/shipping-point',
            },
            {
              title: 'Distributor Parent',
              key: 'distributor',
              attribute: 'Read Distributor',
              url: '/admin/master-data/mst-distributor/distributor',
            },
            {
              title: 'Distributor Child',
              key: 'distributor-child',
              attribute: 'Read Distributor Child',
              url: '/admin/master-data/mst-distributor/distributor-child',
            },
            {
              title: 'Balance Summary',
              key: 'balance-summary',
              attribute: 'Read Approval Balance Summary',
              url: '/admin/master-data/mst-distributor/balance-summary',
            },
          ],
        },
        // {
        //   title: 'Stock',
        //   key: 'stock',
        //   children: [
        //     {
        //       title: 'Category Stock',
        //       key: 'categoryStock',
        //       attribute: 'Read Category Stock',
        //       url: '/admin/master-data/stock/category-stock',
        //     },
        //     {
        //       title: 'Category Sub Stock',
        //       key: 'categorySubStock',
        //       attribute: 'Read Category Sub Stock',
        //       url: '/admin/master-data/stock/category-sub-stock',
        //     },
        //     {
        //       title: 'Stock',
        //       key: 'stock',
        //       attribute: 'Read Stock',
        //       url: '/admin/master-data/stock/stock',
        //     },
        //   ],
        // },
        {
          title: 'Daily Send',
          key: 'daily-send',
          attribute: 'Read Daily Send',
          url: '/admin/master-data/daily-send',
        },
        // {
        //   title: 'Claim Promo EPM',
        //   key: 'claim-promo-epm',
        //   attribute: 'Read Claim Promo EPM',
        //   url: '/admin/master-data/claim-promo-epm',
        // },
        {
          title: 'Outlet',
          key: 'outlet',
          children: [
            {
              title: 'Channel',
              key: 'outletChannel',
              attribute: 'Read Outlet Channel',
              url: '/admin/master-data/outlet/outlet-channel',
            },
            {
              title: 'Channel Sub',
              key: 'outletChannelSub',
              attribute: 'Read Outlet Channel Sub',
              url: '/admin/master-data/outlet/outlet-channel-sub',
            },
            {
              title: 'Sales Type',
              key: 'outletSalesType',
              attribute: 'Read Sales Type',
              url: '/admin/master-data/outlet/outlet-sales-type',
            },
            {
              title: 'Sales SPV',
              key: 'outletSalesSpv',
              attribute: 'Read Outlet Sales SPV',
              url: '/admin/master-data/outlet/outlet-sales-spv',
            },
            {
              title: 'Sales',
              key: 'outletSales',
              attribute: 'Read Outlet Sales',
              url: '/admin/master-data/outlet/outlet-sales',
            },
            {
              title: 'MD',
              key: 'outletMd',
              attribute: 'Read Outlet MD',
              url: '/admin/master-data/outlet/outlet-md',
            },
            {
              title: 'TL SPV',
              key: 'outletTlSpv',
              attribute: 'Read Outlet TL SPV',
              url: '/admin/master-data/outlet/outlet-tl-spv',
            },
            {
              title: 'TL',
              key: 'outletTl',
              attribute: 'Read Outlet TL',
              url: '/admin/master-data/outlet/outlet-tl',
            },
            {
              title: 'BP Type',
              key: 'outletSpgType',
              attribute: 'Read SPG Type',
              url: '/admin/master-data/outlet/outlet-spg-type',
            },
            {
              title: 'BP Position',
              key: 'outletSpgPosition',
              attribute: 'Read SPG Position',
              url: '/admin/master-data/outlet/outlet-spg-position',
            },
            {
              title: 'BP',
              key: 'outletSpg',
              attribute: 'Read Outlet SPG',
              url: '/admin/master-data/outlet/outlet-spg',
            },
            {
              title: 'Outlet Group',
              key: 'outletGroup',
              attribute: 'Read Outlet Group',
              url: '/admin/master-data/outlet/outlet-group',
            },
            {
              title: 'Outlet',
              key: 'outlet',
              attribute: 'Read Outlet',
              url: '/admin/master-data/outlet/outlet',
            },
            {
              title: 'Outlet Dist',
              key: 'outletDist',
              attribute: 'Read Outlet Dist',
              url: '/admin/master-data/outlet/outlet-dist',
            },
            {
              title: 'Outlet Priority',
              key: 'outletPriority',
              attribute: 'Read Outlet Priority',
              url: '/admin/master-data/outlet/outlet-priority',
            },
            {
              title: 'Outlet LatLng & Image',
              key: 'outletLatLngImage',
              attribute: 'Read Outlet LatLng & Image',
              url: '/admin/master-data/outlet/outlet-latlng-image',
            },
          ],
        },
        {
          title: 'Target',
          key: 'target',
          children: [
            {
              title: 'Target Primary Sales',
              key: 'targetPrimarySales',
              attribute: 'Read Target Primary Sales',
              url: '/admin/master-data/target/target-primary-sales/',
            },
            {
              title: 'Target Primary Brand',
              key: 'targetPrimaryBrand',
              attribute: 'Read Target Primary Brand',
              url: '/admin/master-data/target/target-primary-brand/',
            },
            {
              title: 'Target Secondary Dist',
              key: 'targetSecondaryDist',
              attribute: 'Read Target Secondary Dist',
              url: '/admin/master-data/target/target-secondary-dist/',
            },
            {
              title: 'Forecast Secondary Sales',
              key: 'forecastTargetSecondarySales',
              attribute: 'Read Forecast Target Secondary Sales',
              url: '/admin/master-data/target/forecast-target-secondary-sales/',
            },
            // {
            //   title: 'Target Secondary Sales',
            //   key: 'targetSecondarySales',
            //   attribute: 'Read Target Secondary Sales',
            //   url: '/admin/master-data/target/target-secondary-sales/',
            // },
            {
              title: 'Target Secondary Brand',
              key: 'targetSecondaryBrand',
              attribute: 'Read Target Secondary Brand',
              url: '/admin/master-data/target/target-secondary-brand/',
            },
            {
              title: 'Target Secondary Channel Sub',
              key: 'targetSecondaryChannelSub',
              attribute: 'Read Target Secondary Channel Sub',
              url: '/admin/master-data/target/target-secondary-channel-sub/',
            },
            {
              title: 'Target Secondary LCS',
              key: 'targetSecondarySalesLcs',
              attribute: 'Read Target Secondary Sales LCS',
              url: '/admin/master-data/target/target-secondary-sales-lcs/',
            },
            {
              title: 'Target Secondary CB',
              key: 'targetSecondarySalesCb',
              attribute: 'Read Target Secondary Sales CB',
              url: '/admin/master-data/target/target-secondary-sales-cb/',
            },
            {
              title: 'Target Secondary Rental',
              key: 'targetSecondarySalesRental',
              attribute: 'Read Target Secondary Sales Rental',
              url: '/admin/master-data/target/target-secondary-sales-rental/',
            },
            {
              title: 'Target Secondary Outlet',
              key: 'targetSecondarySalesOutlet',
              attribute: 'Read Target Secondary Sales Outlet',
              url: '/admin/master-data/target/target-secondary-sales-outlet/',
            },
            {
              title: 'Target Secondary BP',
              key: 'targetSecondarySalesSpg',
              attribute: 'Read Target Secondary Sales SPG',
              url: '/admin/master-data/target/target-secondary-sales-spg/',
            },
            // {
            //   title: 'Target Incentive MSS',
            //   key: 'targetIncentiveMss',
            //   attribute: 'Read Target Incentive MSS',
            //   url: '/admin/master-data/target/target-incentive-mss/',
            // },
            {
              title: 'Target TLSD & LoP',
              key: 'targetTlsdLopSales',
              attribute: 'Read Target Total Line Sold & Line of Product Sales',
              url: '/admin/master-data/target/target-tlsd-lop-sales/',
            },
            {
              title: 'Target RO',
              key: 'targetRoSales',
              attribute: 'Read Target RO Sales',
              url: '/admin/master-data/target/target-ro-sales/',
            },
            {
              title: 'Target Day of Work',
              key: 'targetDayOfWork',
              attribute: 'Read Target Day Of Work',
              url: '/admin/master-data/target/target-day-of-work/',
            },
          ],
        },
        {
          title: 'Plugin',
          key: 'plugin',
          children: [
            {
              title: 'Period',
              key: 'period',
              attribute: 'Read Plugin Period',
              url: '/admin/master-data/plugin/period',
            },
            {
              title: 'Year',
              key: 'year',
              attribute: 'Read Plugin Year',
              url: '/admin/master-data/plugin/year',
            },
            {
              title: 'Month',
              key: 'month',
              attribute: 'Read Plugin Month',
              url: '/admin/master-data/plugin/month',
            },
            {
              title: 'Schedule',
              key: 'schedule',
              attribute: 'Read Plugin Schedule',
              url: '/admin/master-data/plugin/schedule',
            },
            {
              title: 'Schedule Accounting',
              key: 'scheduleAccounting',
              attribute: 'Read Plugin Schedule Accounting',
              url: '/admin/master-data/plugin/schedule-accounting',
            },
            {
              title: 'Closing Month',
              key: 'closing-month',
              attribute: 'Read Plugin Closing Month',
              url: '/admin/master-data/plugin/closing-month',
            },
            {
              title: 'Version',
              key: 'version',
              attribute: 'Read Plugin Version',
              url: '/admin/master-data/plugin/version',
            },
          ],
        },
      ],
    },
    {
      title: 'Master SFA',
      key: 'master-sfa',
      icon: 'fe fe-database',
      auths: ['user'],
      children: [
        {
          title: 'Product',
          key: 'sfaProduct',
          children: [
            {
              title: 'Product TOP',
              key: 'sfaProductTop',
              attribute: 'Read SFA Product Top',
              url: '/admin/master-sfa/product/product-top',
            },
          ],
        },
        {
          title: 'Banner',
          key: 'banner',
          attribute: 'Read SFA Banner',
          url: '/admin/master-sfa/banner',
        },
        {
          title: 'PK Module',
          key: 'pkModule',
          attribute: 'Read SFA PK Module',
          url: '/admin/master-sfa/pk-module',
        },
        {
          title: 'Trade Promo',
          key: 'tradePromo',
          attribute: 'Read SFA Trade Promo',
          url: '/admin/master-sfa/trade-promo',
        },
        {
          title: 'Quiz',
          key: 'quiz',
          attribute: 'Read SFA Quiz',
          url: '/admin/master-sfa/quiz',
        },
        {
          title: 'Program',
          key: 'program',
          auths: ['user'],
          children: [
            {
              title: 'Rank',
              key: 'programRank',
              attribute: 'Read SFA Program Rank',
              url: '/admin/master-sfa/program/rank',
            },
            {
              title: 'Regular',
              key: 'programRegular',
              attribute: 'Read SFA Program Regular',
              url: '/admin/master-sfa/program/regular',
            },
            {
              title: 'Rental',
              key: 'programRental',
              attribute: 'Read SFA Program Rental',
              url: '/admin/master-sfa/program/rental',
            },
          ],
        },
        {
          title: 'Consumer',
          key: 'consumer',
          auths: ['user'],
          children: [
            {
              title: 'Channel Product Bundle',
              key: 'consumerChannelProductBundle',
              attribute: 'Read SFA Consumer Channel Product Bundle',
              url: '/admin/master-sfa/consumer/channel-product-bundle',
            },
            {
              title: 'Channel Product',
              key: 'consumerChannelProduct',
              attribute: 'Read SFA Consumer Channel Product',
              url: '/admin/master-sfa/consumer/channel-product',
            },
            {
              title: 'Channel Range',
              key: 'consumerChannelRange',
              attribute: 'Read SFA Consumer Channel Range',
              url: '/admin/master-sfa/consumer/channel-range',
            },
            {
              title: 'Channel Brand',
              key: 'consumerChannelBrand',
              attribute: 'Read SFA Consumer Channel Brand',
              url: '/admin/master-sfa/consumer/channel-brand',
            },
            {
              title: 'Channel',
              key: 'consumerChannel',
              attribute: 'Read SFA Consumer Channel',
              url: '/admin/master-sfa/consumer/channel',
            },
            {
              title: 'Dist Channel Product Bundle',
              key: 'consumerDistributorProductBundle',
              attribute: 'Read SFA Consumer Distributor Channel Product Bundle',
              url: '/admin/master-sfa/consumer/distributor-product-bundle',
            },
            {
              title: 'Dist Channel Product',
              key: 'consumerDistributorProduct',
              attribute: 'Read SFA Consumer Distributor Channel Product',
              url: '/admin/master-sfa/consumer/distributor-product',
            },
            {
              title: 'Dist Channel Range',
              key: 'consumerDistributorRange',
              attribute: 'Read SFA Consumer Distributor Channel Range',
              url: '/admin/master-sfa/consumer/distributor-range',
            },
            {
              title: 'Dist Channel Brand',
              key: 'consumerDistributorBrand',
              attribute: 'Read SFA Consumer Distributor Channel Brand',
              url: '/admin/master-sfa/consumer/distributor-brand',
            },
            {
              title: 'Dist Channel',
              key: 'consumerDistributor',
              attribute: 'Read SFA Consumer Distributor Channel',
              url: '/admin/master-sfa/consumer/distributor',
            },
            {
              title: 'Outlet Product Bundle',
              key: 'consumerOutletProductBundle',
              attribute: 'Read SFA Consumer Outlet Product Bundle',
              url: '/admin/master-sfa/consumer/outlet-product-bundle',
            },
            {
              title: 'Outlet Product',
              key: 'consumerOutletProduct',
              attribute: 'Read SFA Consumer Outlet Product',
              url: '/admin/master-sfa/consumer/outlet-product',
            },
            {
              title: 'Outlet Range',
              key: 'consumerOutletRange',
              attribute: 'Read SFA Consumer Outlet Range',
              url: '/admin/master-sfa/consumer/outlet-range',
            },
            {
              title: 'Outlet Brand',
              key: 'consumerOutletBrand',
              attribute: 'Read SFA Consumer Outlet Brand',
              url: '/admin/master-sfa/consumer/outlet-brand',
            },
            {
              title: 'Outlet',
              key: 'consumerOutlet',
              attribute: 'Read SFA Consumer Outlet',
              url: '/admin/master-sfa/consumer/outlet',
            },
          ],
        },
      ],
    },
    {
      title: 'Upload Data',
      key: 'uploadConverterNew',
      icon: 'fe fe-upload',
      auths: ['user'],
      attribute: 'Read Trans Dist Upload Draft',
      url: '/admin/monitoring-data/upload-converter-new',
    },
    {
      title: 'Upload Data Stock',
      key: 'uploadConverterNewStock',
      icon: 'fe fe-upload-cloud',
      auths: ['user'],
      attribute: 'Read Trans Dist Upload Stock Draft',
      url: '/admin/monitoring-data/upload-converter-new-stock',
    },
    {
      title: 'Upload Data AR',
      key: 'uploadDataAr',
      icon: 'fe fe-upload',
      auths: ['user'],
      attribute: 'Read AR',
      url: '/admin/monitoring-data/ar',
    },
    {
      title: 'Monitoring Data',
      url: '/monitoring-data',
      key: 'monitoringData',
      icon: 'fe fe-monitor',
      auths: ['user'],
      children: [
        {
          title: 'Data Upload',
          key: 'dataUploadSummary',
          attribute: 'Read Trans Dist Upload',
          url: '/admin/monitoring-data/data-upload-detail',
        },
        {
          title: 'Data Upload (Daily)',
          key: 'transDistUploadDaily',
          attribute: 'Read Trans Dist Upload Daily',
          url: '/admin/transaction/trans-dist-upload-daily',
        },
        {
          title: 'Data Upload (Weekly)',
          key: 'transDistUploadWeekly',
          attribute: 'Read Trans Dist Upload Weekly',
          url: '/admin/transaction/trans-dist-upload-weekly',
        },
        {
          title: 'Queue Process',
          key: 'queueProcess',
          attribute: 'Read Queue Trans Dist Upload',
          url: '/admin/monitoring-data/data-upload-detail/queue-process',
        },
        {
          title: 'Convert Union',
          key: 'convertUnion',
          attribute: 'Read Convert Union',
          url: '/admin/transaction/convert-union',
        },
        {
          title: 'Status Discount',
          key: 'transDistStatusDiscount',
          attribute: 'Read Trans Dist Status Discount',
          url: '/admin/transaction/trans-dist-status-discount',
        },
        {
          title: 'Status MMA & MMAX',
          key: 'transDistStatusCubeMmaMax',
          attribute: 'Read Trans Dist Status Cube MMA - MMAX',
          url: '/admin/transaction/trans-dist-status-cube-mma-mmax',
        },
        {
          title: 'Status Cube',
          key: 'transDistStatusCube',
          attribute: 'Read Trans Dist Status Cube',
          url: '/admin/transaction/trans-dist-status-cube',
        },

        {
          title: 'Status Cube Ewom',
          key: 'transDistStatusCubeEwom',
          attribute: 'Read Trans Dist Status Cube E-WOM',
          url: '/admin/transaction/trans-dist-status-cube-ewom',
        },
      ],
    },
    {
      title: 'Monitoring Data Stock',
      url: '/monitoring-data-stock',
      key: 'monitoringDataStock',
      icon: 'fe fe-tv',
      auths: ['user'],
      children: [
        {
          title: 'Data Upload',
          key: 'dataUploadSummaryStock',
          attribute: 'Read Trans Dist Upload Stock',
          url: '/admin/monitoring-data/data-upload-detail-stock',
        },
        {
          title: 'Data Upload (Daily)',
          key: 'transDistUploadStockDaily',
          attribute: 'Read Trans Dist Upload Stock Daily',
          url: '/admin/transaction/trans-dist-upload-stock-daily',
        },
        {
          title: 'Data Upload (Weekly)',
          key: 'transDistUploadStockWeekly',
          attribute: 'Read Trans Dist Upload Stock Weekly',
          url: '/admin/transaction/trans-dist-upload-stock-weekly',
        },
        {
          title: 'Queue Process',
          key: 'queueProcessStock',
          attribute: 'Read Queue Trans Dist Upload Stock',
          url: '/admin/monitoring-data/data-upload-detail/queue-process-stock',
        },
        {
          title: 'Convert Union',
          key: 'convertUnionStock',
          attribute: 'Read Convert Union Stock',
          url: '/admin/transaction/convert-union-stock',
        },
      ],
    },
    {
      title: 'Report Mail',
      key: 'reportMail',
      icon: 'fe fe-mail',
      attribute: 'Read Report Mail',
      auths: ['user'],
      url: '/admin/report-mail',
    },
    {
      title: 'Report',
      url: '/export-report',
      key: 'exportReport',
      icon: 'fe fe-download-cloud',
      auths: ['user'],
      children: [
        {
          title: 'Report Detail',
          key: 'transFinalReport',
          attribute: 'Read Trans Final Report',
          url: '/admin/transaction/trans-final-report',
        },
        {
          title: 'Report Detail Stock',
          key: 'transFinalReportStock',
          attribute: 'Read Trans Final Report Stock',
          url: '/admin/transaction/trans-final-report-stock',
        },
        {
          title: 'Report Ewom',
          key: 'reportEwom',
          attribute: 'Read Report E-WOM',
          url: '/admin/export-report/report-ewom',
        },
        {
          title: 'Report Ewom Recap Salesman',
          key: 'reportRecapSalesmanEwom',
          attribute: 'Read Report Recap Salesman E-WOM',
          url: '/admin/export-report/report-recap-salesman-ewom',
        },
        {
          title: 'Report Ewom Recap MD & BP',
          key: 'reportRecapMdSpgEwom',
          attribute: 'Read Report Recap MD / SPG E-WOM',
          url: '/admin/export-report/report-recap-md-spg-ewom',
        },
        {
          title: 'Report Monthly Review',
          key: 'reportMr',
          attribute: 'Read Report MR',
          url: '/admin/export-report/report-mr',
        },

        {
          title: 'Report Based Estimate',
          key: 'reportBasedEstimate',
          attribute: 'Read Report Based Estimate',
          url: '/admin/export-report/report-based-estimate',
        },
        {
          title: 'Report BE ASS',
          key: 'reportBeAss',
          attribute: 'Read Report BE ASS',
          url: '/admin/export-report/report-be-ass',
        },
        {
          title: 'Report Business Review',
          key: 'reportBusinessReview',
          attribute: 'Read Report Business Review',
          url: '/admin/export-report/report-business-review',
        },
        {
          title: 'Report Target vs Ach Period By Brand',
          key: 'reportTargetAchPeriodByBrand',
          attribute: 'Read Report Target vs Ach Period By Brand',
          url: '/admin/export-report/report-target-ach-period-by-brand',
        },
        // {
        //   title: 'Report Ach by Brand',
        //   key: 'reportAchByBrand',
        //   attribute: 'Read Report Ach By Brand',
        //   url: '/admin/export-report/report-ach-by-brand',
        // },
        // {
        //   title: 'Report Ach by Type Channel',
        //   key: 'reportAchByTypeChannel',
        //   attribute: 'Read Report Ach By Type Channel',
        //   url: '/admin/export-report/report-ach-by-type-channel',
        // },
        // {
        //   title: 'Report Ach by Salesman',
        //   key: 'reportAchBySalesman',
        //   attribute: 'Read Report Ach By Salesman',
        //   url: '/admin/export-report/report-ach-by-salesman',
        // },
        // {
        //   title: 'Report AO by Type Channel',
        //   key: 'reportAoByTypeChannel',
        //   attribute: 'Read Report AO By Type Channel',
        //   url: '/admin/export-report/report-ao-by-type-channel',
        // },
        // {
        //   title: 'Report AO by Salesman',
        //   key: 'reportAoBySalesman',
        //   attribute: 'Read Report AO By Salesman',
        //   url: '/admin/export-report/report-ao-by-salesman',
        // },
        // {
        //   title: 'Report EC by Salesman',
        //   key: 'reportEcBySalesman',
        //   attribute: 'Read Report EC By Salesman',
        //   url: '/admin/export-report/report-ec-by-salesman',
        // },
        // {
        //   title: 'Report Brand by Salesman',
        //   key: 'reportBrandBySalesman',
        //   attribute: 'Read Report Brand By Salesman',
        //   url: '/admin/export-report/report-brand-by-salesman',
        // },
        // {
        //   title: 'Report Type Channel by Salesman',
        //   key: 'reportTypeChannelBySalesman',
        //   attribute: 'Read Report Type Channel By Salesman',
        //   url: '/admin/export-report/report-type-channel-by-salesman',
        // },
        // {
        //   title: 'Report TOP Paretto',
        //   key: 'reportTopParetto',
        //   attribute: 'Read Report Top Paretto',
        //   url: '/admin/export-report/report-top-paretto',
        // },
        {
          title: 'Report No Trans 2 MMA Outlet',
          key: 'reportNoTransTwoMmaOutlet',
          attribute: 'Read Report No Trans 2MMA Outlet',
          url: '/admin/export-report/report-no-trans-two-mma-outlet',
        },
        {
          title: 'Report No Trans 3 MMA Product',
          key: 'reportNoTransThreeMma',
          attribute: 'Read Report No Trans 3MMA Product',
          url: '/admin/export-report/report-no-trans-three-mma-product',
        },
        {
          title: 'Report No Trans 4 MMA Outlet',
          key: 'reportNoTransFourMmaOutlet',
          attribute: 'Read Report No Trans 4MMA Outlet',
          url: '/admin/export-report/report-no-trans-four-mma-outlet',
        },
        // {
        //   title: 'Report No Trans 3 MMA Outlet',
        //   key: 'reportNoTransThreeMmaOutletOnly',
        //   attribute: 'Read Report No Trans 3MMA Outlet',
        //   url: '/admin/export-report/report-no-trans-three-mma-outlet',
        // },
        // {
        //   title: 'Report NOO Tracking',
        //   key: 'reportNooTracking',
        //   attribute: 'Read Report NOO Tracking',
        //   url: '/admin/export-report/report-noo-tracking',
        // },
        // {
        //   title: 'Report ROM',
        //   key: 'reportRom',
        //   attribute: 'Read Report ROM',
        //   url: '/admin/export-report/report-rom',
        // },
        {
          title: 'Report Balance Summary',
          key: 'reportBalanceSummary',
          attribute: 'Read Report Balance Summary',
          url: '/admin/export-report/report-balance-summary',
        },
        // {
        //   title: 'Report MD Jago Jualan',
        //   key: 'reportMdJagoJualan',
        //   attribute: 'Read Report MD Jago Jualan',
        //   url: '/admin/export-report/report-md-jago-jualan',
        // },
        {
          title: 'Report MSS',
          key: 'reportMss',
          attribute: 'Read Report MSS',
          url: '/admin/export-report/report-mss',
        },
        {
          title: 'Report Primary Sales',
          key: 'reportPrimarySales',
          attribute: 'Read Report Primary Sales',
          url: '/admin/export-report/report-primary-sales',
        },
        {
          title: 'Report Primary Sales By Brand',
          key: 'reportPrimarySalesByBrand',
          attribute: 'Read Report Primary Sales By Brand',
          url: '/admin/export-report/report-primary-sales-by-brand',
        },
        {
          title: 'Report Weekly Primary Sales',
          key: 'reportWeeklyPrimarySales',
          attribute: 'Read Report Weekly Primary Sales',
          url: '/admin/export-report/report-weekly-primary-sales',
        },
        {
          title: 'Report DO By Daily',
          key: 'reportDoByDaily',
          attribute: 'Read Report DO By Daily',
          url: '/admin/export-report/report-do-by-daily',
        },
        {
          title: 'Report DO',
          key: 'reportDo',
          attribute: 'Read Report DO',
          url: '/admin/export-report/report-do',
        },
        {
          title: 'Report SO vs DO',
          key: 'reportSoVsDo',
          attribute: 'Read Report SO vs DO',
          url: '/admin/export-report/report-so-vs-do',
        },
        {
          title: 'Report DO By Distributor',
          key: 'reportDoByDistributor',
          attribute: 'Read Report DO By Distributor',
          url: '/admin/export-report/report-do-by-distributor',
        },
        {
          title: 'Report Productivity SFA vs STAR',
          key: 'reportProductivitySfaVsStar',
          attribute: 'Read Report Productivity SFA vs STAR',
          url: '/admin/export-report/report-productivity-sfa-vs-star',
        },
        {
          title: 'Report Daily Brand Sec Sales Summary',
          key: 'reportDailyBrandSecSalesSummary',
          attribute: 'Read Report Daily Brand Sec Sales Summary',
          url: '/admin/export-report/report-daily-brand-sec-sales-summary',
        },
        {
          title: 'Report Sec Sales & Productivity',
          key: 'reportSecSalesAndProductivity',
          attribute: 'Read Report Sec Sales And Productivity',
          url: '/admin/export-report/report-sec-sales-and-productivity',
        },
        {
          title: 'Report Sec Sales By Brand',
          key: 'reportSecSalesByBrand',
          attribute: 'Read Report Sec Sales By Brand',
          url: '/admin/export-report/report-sec-sales-by-brand',
        },
        {
          title: 'Report KA Sec Sales & Productivity',
          key: 'reportKaSecSalesAndProductivity',
          attribute: 'Read Report KA Sec Sales And Productivity',
          url: '/admin/export-report/report-ka-sec-sales-and-productivity',
        },
        {
          title: 'Report Sales Sec MTD',
          key: 'reportSalesSecondaryMtd',
          attribute: 'Read Report Sales Secondary MTD',
          url: '/admin/export-report/report-sales-secondary-mtd',
        },
        {
          title: 'Report Sales Sec MTD By Brand',
          key: 'reportSalesSecondaryMtdByBrand',
          attribute: 'Read Report Sales Secondary MTD By Brand',
          url: '/admin/export-report/report-sales-secondary-mtd-by-brand',
        },
        {
          title: 'Report Time Series',
          key: 'reportTimeSeries',
          attribute: 'Read Report Time Series',
          url: '/admin/export-report/report-time-series',
        },
        {
          title: 'Report Time Series Promotor',
          key: 'reportTimeSeriesPromotor',
          attribute: 'Read Report Time Series Promotor',
          url: '/admin/export-report/report-time-series-promotor',
        },
        {
          title: 'Report Productivity By Master',
          key: 'reportProductivityByMaster',
          attribute: 'Read Report Productivity By Master',
          url: '/admin/export-report/report-productivity-by-master',
        },
        {
          title: 'Report History Outlet',
          key: 'reportHistoryOutlet',
          attribute: 'Read Report History Outlet',
          url: '/admin/export-report/report-history-outlet',
        },
        {
          title: 'Report Product TOP 135 & 100',
          key: 'reportProductTop100And50',
          attribute: 'Read Report Product TOP 135 & 100',
          url: '/admin/export-report/report-product-top-135-and-100',
        },
        {
          title: 'Report OPSC - Cosmo By Outlet And Range',
          key: 'reportOpscCosmoByOutletAndRange',
          attribute: 'Read Report OPSC - Cosmo By Outlet And Range',
          url: '/admin/export-report/report-opsc-cosmo-by-outlet-and-range',
        },
        // {
        //   title: 'Report Product TOP 100',
        //   key: 'reportProductTop100',
        //   attribute: 'Read Report Product TOP 100',
        //   url: '/admin/export-report/report-product-top-100',
        // },
      ],
    },
    {
      title: 'Report Incentive',
      url: '/export-report-incentive',
      key: 'exportReportIncentive',
      icon: 'fe fe-arrow-down-circle',
      auths: ['user'],
      children: [
        {
          title: 'Report Incentive Distributor',
          key: 'reportIncentiveDistributor',
          attribute: 'Read Report Incentive Distributor',
          url: '/admin/export-report/report-incentive-distributor',
        },
        {
          title: 'Report Incentive RSM',
          key: 'reportIncentiveRsm',
          attribute: 'Read Report Incentive RSM',
          url: '/admin/export-report/report-incentive-rsm',
        },
        {
          title: 'Report Incentive ASM',
          key: 'reportIncentiveAsm',
          attribute: 'Read Report Incentive ASM',
          url: '/admin/export-report/report-incentive-asm',
        },
        {
          title: 'Report Incentive ASS',
          key: 'reportIncentiveAss',
          attribute: 'Read Report Incentive ASS',
          url: '/admin/export-report/report-incentive-ass',
        },
        {
          title: 'Report Incentive RPS',
          key: 'reportIncentiveRps',
          attribute: 'Read Report Incentive RPS',
          url: '/admin/export-report/report-incentive-rps',
        },
        {
          title: 'Report Incentive Salesman',
          key: 'reportIncentiveSalesman',
          attribute: 'Read Report Incentive Salesman',
          url: '/admin/export-report/report-incentive-salesman',
        },
        // {
        //   title: 'Report Incentive SPV',
        //   key: 'reportIncentiveSpv',
        //   attribute: 'Read Report Incentive SPV',
        //   url: '/admin/export-report/report-incentive-spv',
        // },
        {
          title: 'Report Incentive MD',
          key: 'reportIncentiveMd',
          attribute: 'Read Report SFA Incentive MD',
          url: '/admin/export-report/report-incentive-md',
        },
        {
          title: 'Report Incentive TL',
          key: 'reportIncentiveTl',
          attribute: 'Read Report Incentive TL',
          url: '/admin/export-report/report-incentive-tl',
        },
        {
          title: 'Report Incentive BP',
          key: 'reportIncentiveSpg',
          attribute: 'Read Report Incentive SPG',
          url: '/admin/export-report/report-incentive-spg',
        },
        {
          title: 'Report Incentive TL SPV',
          key: 'reportIncentiveTlSpv',
          attribute: 'Read Report Incentive TL SPV',
          url: '/admin/export-report/report-incentive-tl-spv',
        },
        {
          title: 'Report Incentive Group Promotor Manager',
          key: 'reportIncentiveGroupPromotorManager',
          attribute: 'Read Report Incentive Group Promotor Manager',
          url: '/admin/export-report/report-incentive-group-promotor-manager',
        },
        {
          title: 'Report Incentive HOMT',
          key: 'reportIncentiveHomt',
          attribute: 'Read Report Incentive Head of Modern Trade',
          url: '/admin/export-report/report-incentive-homt',
        },
        {
          title: 'Report Incentive NKAM',
          key: 'reportIncentiveNkam',
          attribute: 'Read Report Incentive National Key Account Manager',
          url: '/admin/export-report/report-incentive-nkam',
        },
        {
          title: 'Report Incentive KAM',
          key: 'reportIncentiveKam',
          attribute: 'Read Report Incentive Key Account Manager',
          url: '/admin/export-report/report-incentive-kam',
        },
        {
          title: 'Report Incentive SOS',
          key: 'reportIncentiveSos',
          attribute: 'Read Report Incentive Sales Operation Supervisor',
          url: '/admin/export-report/report-incentive-sos',
        },
      ],
    },
    {
      title: 'Report SFA',
      url: '/export-report-sfa',
      key: 'exportReportSfa',
      icon: 'fe fe-download',
      auths: ['user'],
      children: [
        {
          title: 'Promotor',
          key: 'reportSfaPromotor',
          children: [
            {
              title: 'Report Absen Promotor',
              key: 'ReportAbsenPromotor',
              attribute: 'Read Report SFA Absen Promotor',
              url: '/admin/export-report-sfa/report-absen-promotor',
            },
          ],
        },
        // {
        //   title: 'Report Daily Tagging Monitoring',
        //   key: 'reportDailyTaggingMonitoring',
        //   attribute: 'Read User', // kemungkinan ini juga diganti :EOF
        //   url: '/admin/export-report-sfa/report-daily-tagging-monitoring',
        // },
        {
          title: 'MD',
          key: 'reportSfaMd',
          children: [
            {
              title: 'Report Call',
              key: 'reportCall',
              attribute: 'Read Report SFA Call MD',
              url: '/admin/export-report-sfa/report-call',
            },
            {
              title: 'Report Absen',
              key: 'reportAbsen',
              attribute: 'Read Report SFA Absen MD',
              url: '/admin/export-report-sfa/report-absen',
            },
            {
              title: 'Report POSM Detail',
              key: 'reportPosmDetail',
              attribute: 'Read Report SFA POSM Detail MD',
              url: '/admin/export-report-sfa/report-posm-detail',
            },
            {
              title: 'Report POSM Summary',
              key: 'ReportPosmSummary',
              attribute: 'Read Report SFA POSM Summary MD',
              url: '/admin/export-report-sfa/report-posm-summary',
            },
            {
              title: 'Report Accuration PJP',
              key: 'ReportAccurationPjp',
              attribute: 'Read Report SFA Accuration PJP',
              url: '/admin/export-report-sfa/report-accuration-pjp',
            },
            {
              title: 'Report Competitor',
              key: 'ReportCompetitor',
              attribute: 'Read Report SFA Competitor',
              url: '/admin/export-report-sfa/report-competitor',
            },
            {
              title: 'Report Activity Daily MD',
              key: 'ReportActivityDailyMd',
              attribute: 'Read Report SFA Activity Daily MD',
              url: '/admin/export-report-sfa/report-activity-daily-md',
            },
            {
              title: 'Report Activity Daily Merchandising',
              key: 'ReportActivityMerchandising',
              attribute: 'Read Report SFA Activity Daily Merchandising',
              url: '/admin/export-report-sfa/report-activity-merchandising',
            },
          ],
        },
        {
          title: 'Management',
          key: 'reportSfaManagement',
          children: [
            {
              title: 'Report Activity Daily SPV',
              key: 'ReportActivityDailySpv',
              attribute: 'Read Report SFA Activity Daily SPV',
              url: '/admin/export-report-sfa/report-activity-daily-spv',
            },
            {
              title: 'Report Absen SPV',
              key: 'ReportAbsenSpv',
              attribute: 'Read Report SFA Absen SPV',
              url: '/admin/export-report-sfa/report-absen-spv',
            },
          ],
        },
        {
          title: 'Salesman',
          key: 'reportSfaSalesman',
          children: [
            {
              title: 'Report Activity Daily SFA',
              key: 'ReportActivityDailySfa',
              attribute: 'Read Report SFA Activity Daily Salesman',
              url: '/admin/export-report-sfa/report-activity-daily-sfa',
            },
            {
              title: 'Report Absen SFA',
              key: 'ReportAbsenSfa',
              attribute: 'Read Report SFA Absen Salesman',
              url: '/admin/export-report-sfa/report-absen-sfa',
            },
            {
              title: 'Report Productivity SFA',
              key: 'ReportProductivitySfa',
              attribute: 'Read Report SFA Productivity Salesman',
              url: '/admin/export-report-sfa/report-productivity-sfa',
            },
            {
              title: 'Report Journey Salesman SFA',
              key: 'ReportJourneySalesmanSfa',
              attribute: 'Read Report SFA Journey Salesman',
              url: '/admin/export-report-sfa/report-journey-salesman-sfa',
            },
            {
              title: 'Report PO Summary Salesman',
              key: 'ReportPoSummarySalesman',
              attribute: 'Read Report SFA PO Summary Salesman',
              url: '/admin/export-report-sfa/report-po-summary-salesman',
            },
            {
              title: 'Report PO Detail Salesman',
              key: 'ReportPoDetailSalesman',
              attribute: 'Read Report SFA PO Detail Salesman',
              url: '/admin/export-report-sfa/report-po-detail-salesman',
            },
            {
              title: 'Report Display Salesman',
              key: 'ReportDisplaySalesman',
              attribute: 'Read Report SFA Display Salesman',
              url: '/admin/export-report-sfa/report-display-salesman',
            },
          ],
        },
      ],
    },
    {
      title: 'Authorization',
      key: 'manage-system',
      icon: 'fe fe-inbox',
      auths: ['user'],
      children: [
        {
          title: 'User Wipro',
          key: 'userWipro',
          attribute: 'Read User',
          url: '/admin/manage-system/user-wipro',
        },
        {
          title: 'Permission',
          key: 'permission',
          attribute: 'Read Permission',
          url: '/admin/manage-system/permission',
        },
        {
          title: 'Role',
          key: 'role',
          attribute: 'Read Role',
          url: '/admin/manage-system/role',
        },
        {
          title: 'Position',
          key: 'position',
          attribute: 'Read Position',
          url: '/admin/master-data/mst-distributor/position',
        },
      ],
    },
    {
      title: 'Setting',
      key: 'settingParent',
      icon: 'fe fe-settings',
      auths: ['user'],
      children: [
        {
          title: 'General',
          key: 'setting',
          attribute: 'Read Setting',
          url: '/admin/setting',
        },
        {
          title: 'Converter',
          key: 'settingConverter',
          attribute: 'Read Setting Converter',
          url: '/admin/setting-converter',
        },
        {
          title: 'PO Summary Salesman',
          key: 'settingPoSummarySalesman',
          attribute: 'Read Setting PO Summary Salesman',
          url: '/admin/setting-po-summary-salesman',
        },
      ],
    },
    {
      title: 'Notification',
      key: 'notification',
      icon: 'fe fe-bell',
      attribute: 'Read Notification',
      auths: ['user'],
      url: '/admin/notification',
    },

    // {
    //   title: 'Transactions',
    //   url: '/transaction',
    //   key: 'transaction',
    //   icon: 'fe fe-share-2',
    //   auths: ['userz'],
    //   children: [
    //     {
    //       title: 'Data Upload (Weekly)',
    //       key: 'transDistUploadWeekly',
    //       attribute: 'Read Trans Dist Upload Weekly',
    //       url: '/admin/transaction/trans-dist-upload-weekly',
    //     },
    //   ],
    // },

    // {
    //   title: 'Notification',
    //   key: 'notification',
    //   icon: 'fe fe-bell',
    //   attribute: 'Read Notification',
    //   url: '/admin/notification',
    //   auths: ['user'],
    // },
    {
      title: 'Home',
      key: 'home',
      icon: 'fe fe-home',
      url: '/distributor/home',
      auths: ['distributor'],
    },
    {
      title: 'Setting Profile',
      key: 'settingProfile',
      icon: 'fe fe-user',
      url: '/distributor/profile',
      auths: ['distributor'],
    },
    {
      title: 'Dashboard',
      key: 'dashboard',
      icon: 'fe fe-globe',
      auths: ['distributor'],
      children: [
        {
          title: 'Distributor',
          key: 'dashboard-distributor',
          url: '/distributor/dashboard/distributor',
        },
        {
          title: 'Sales',
          key: 'dashboard-sales',
          url: '/distributor/dashboard/sales',
        },
        {
          title: 'SFA',
          key: 'dashboard-sfa',
          url: '/distributor/dashboard/sfa',
        },
        {
          title: 'Kindis',
          key: 'dashboard-kindis',
          url: '/distributor/dashboard/kindis',
        },
      ],
    },
    {
      title: 'Master Data',
      key: 'master-data',
      icon: 'fe fe-layers',
      auths: ['distributor'],
      children: [
        {
          title: 'Product Dist',
          key: 'productDist',
          url: '/distributor/product-dist',
        },
        {
          title: 'Outlet Dist',
          key: 'outletDist',
          url: '/distributor/outlet-dist',
        },
      ],
    },
    {
      title: 'Upload',
      key: 'upload',
      icon: 'fe fe-upload',
      url: '/distributor/upload-converter-distributor',
      auths: ['distributor'],
    },
    {
      title: 'Upload Stock',
      key: 'uploadStock',
      icon: 'fe fe-upload-cloud',
      url: '/distributor/upload-converter-distributor-stock',
      auths: ['distributor'],
    },
    {
      title: 'Monitoring Data',
      key: 'monitoringData',
      icon: 'fe fe-monitor',
      url: '/distributor/monitoring-data',
      auths: ['distributor'],
    },
    {
      title: 'Monitoring Data Stock',
      key: 'monitoringDataStock',
      icon: 'fe fe-tv',
      url: '/distributor/monitoring-data-stock',
      auths: ['distributor'],
    },
    {
      title: 'Report',
      key: 'exportReport',
      icon: 'fe fe-download-cloud',
      auths: ['distributor'],
      children: [
        {
          title: 'Report Detail',
          key: 'transFinalReport',
          url: '/distributor/trans-final-report',
        },
        {
          title: 'Report Detail Stock',
          key: 'transFinalReportStock',
          url: '/distributor/trans-final-report-stock',
        },
        {
          title: 'Report Ewom',
          key: 'reportEwom',
          url: '/distributor/export-report/report-ewom',
        },
        {
          title: 'Report Monthly Review',
          key: 'reportMr',
          url: '/distributor/export-report/report-mr',
        },
        {
          title: 'Report Based Estimate',
          key: 'reportBasedEstimate',
          url: '/distributor/export-report/report-based-estimate',
        },
        {
          title: 'Report Business Review',
          key: 'reportBusinessReview',
          url: '/distributor/export-report/report-business-review',
        },
      ],
    },
    // {
    //   title: 'Queue Process',
    //   key: 'queueProcess',
    //   icon: 'fe fe-file',
    //   url: '/distributor/queue-process',
    //   auths: ['distributor'],
    // },
    // {
    //   title: 'Queue Process Stock',
    //   key: 'queueProcessStock',
    //   icon: 'fe fe-file-text',
    //   url: '/distributor/queue-process-stock',
    //   auths: ['distributor'],
    // },
    {
      title: 'Report SFA',
      url: '/export-report-sfa',
      key: 'exportReportSfa',
      icon: 'fe fe-download-cloud',
      auths: ['distributor'],
      children: [
        {
          title: 'Report Activity Daily SFA',
          key: 'ReportActivityDailySfa',
          url: '/distributor/export-report-sfa/report-activity-daily-sfa',
        },
        {
          title: 'Report Absen SFA',
          key: 'ReportAbsenSfa',
          url: '/distributor/export-report-sfa/report-absen-sfa',
        },
        {
          title: 'Report Productivity SFA',
          key: 'ReportProductivitySfa',
          url: '/distributor/export-report-sfa/report-productivity-sfa',
        },
        {
          title: 'Report Journey Salesman SFA',
          key: 'ReportJourneySalesmanSfa',
          url: '/distributor/export-report-sfa/report-journey-salesman-sfa',
        },
        {
          title: 'Report PO Summary Salesman',
          key: 'ReportPoSummarySalesman',
          url: '/distributor/export-report-sfa/report-po-summary-salesman',
        },
        {
          title: 'Report PO Detail Salesman',
          key: 'ReportPoDetailSalesman',
          url: '/distributor/export-report-sfa/report-po-detail-salesman',
        },
      ],
    },
  ]
}
